import { faFacebookF, faInstagram, faSpotify, faTiktok } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { getSpotifyToken } from "../../services/spotifyService";
import { SpotifyArtist } from "../../services/interfaces";

function SocialStats() {

    const [token, setToken] = useState<string | null>(null);
    const [socialStats, setSocialStats] = useState<SpotifyArtist | null>(null);

    useEffect(() => {
        const fetchToken = async () => {
            const fetchedToken = await getSpotifyToken();
            setToken(fetchedToken);
        };

        fetchToken();
    }, []);

    // Fetch social stats from Spotify
    useEffect(() => {
        const fetchSocialStats = async () => {
            if (!token) {
                return;
            }

            console.log("Token:", token);

            try {
                const response = await fetch('https://api.spotify.com/v1/artists/5UFq82PvmEauTLHRqGJIFv', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                if (!response.ok) {
                    throw new Error('Failed to fetch Spotify data');
                }
                const data: SpotifyArtist = await response.json();
                setSocialStats(data);
            } catch (error) {
                console.error(error);
            }
        };
        fetchSocialStats();
    }, [token]);


    return (

        <section className="live-alt" id="live-alt">

            <Row className="section-intro">
                <Col className="col-twelve">
                    <h5>SOCIALS</h5>
                </Col>
            </Row>
            <Row className="portfolio-content" style={{ textAlign: 'center' }}>
                <Col className="col-three onesheet-social">
                    <a href="https://www.instagram.com/avainthedark/" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faInstagram} size="xl" style={{ marginBottom: "20px" }} />
                        <h3>Instagram</h3>
                        <h3>Followers: 1,688</h3>
                    </a>
                </Col>
                <Col className="col-three onesheet-social">
                    <a href="https://open.spotify.com/artist/5UFq82PvmEauTLHRqGJIFv?si=AEvAfv0zQFGyIFu5T8aJUw" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faSpotify} size="xl" style={{ marginBottom: "20px" }} />
                        <h3>Spotify</h3>
                        {socialStats ? (<>
                            <h3 style={{ marginBottom: "0.2px" }}> Followers: {socialStats.followers.total.toString()}</h3>
                            <h3 >Monthly Listeners: 653 </h3>
                        </>
                        ) : (<>
                            <h3 style={{ marginBottom: "0.2px" }}>Followers: 676 </h3>
                            <h3>Monthly Listeners: 653 </h3>
                        </>)}
                    </a>
                </Col>
                <Col className="col-three onesheet-social">
                    <a href="https://www.tiktok.com/@avainthedark" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faTiktok} size="xl" style={{ marginBottom: "20px" }} />
                        <h3>TikTok</h3>
                        <h3>Followers: 717</h3>
                    </a>
                </Col>
                <Col className="col-three onesheet-social">

                    <a href="https://www.facebook.com/AvaInTheDark/" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faFacebookF} size="xl" style={{ marginBottom: "20px" }} />
                        <h3>Facebook</h3>
                        <h3>Followers: 863</h3>
                    </a>
                </Col>
            </Row>
        </section>
    );
}

export default SocialStats;