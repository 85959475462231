import { Row, Col } from "react-bootstrap";

export default function SwimSection() {
    return (
        <section className="live" id="live" style={{ backgroundColor: "#081724" }}>

            <Row className="section-intro">
                <Col className="col-twelve">
                    <h5>SWIM</h5>
                </Col>
            </Row>

            <Row>
                <Col className="col-twelve" style={{ textAlign: "justify", alignContent: "center", paddingBottom: "1em" }}>
                    For Kiera, "SWIM" emerged from the depths of life-altering experiences that blurred the line between reality and introspection. The track navigates the murky waters of consciousness, where lucid dreams intertwine with intrusive thoughts,
                    and the very fabric of right and wrong unravels. Instead of seeking clarity or resolution, Ava in the Dark delves into the confusion, allowing the song to float in the space between existential questioning and the quiet desire for a life just beyond reach.
                    "SWIM" captures this suspended moment, a collision of dreamlike soundscapes and the raw pull of self-reflection, carried by the band’s signature blend of atmospheric intensity.
                </Col>

            </Row>

            <Row style={{ alignContent: "center", textAlign: "center" }}>
                <Col className="col-twelve" style={{ alignContent: "center" }}>
                    <img src="./img/SwimArtwork.jpg" alt="swim artwork" style={{ height: "400px", margin: "1em auto 1em auto", display: "block" }} />
                    <p>'Swim' official artwork by Kiera Bickerstaff</p>
                </Col>
            </Row>

            {/*
            <Row className="section-intro">
                <Col className="col-twelve">
                    <h5>Exposure</h5>
                </Col>
            </Row>


            <Row style={{ alignContent: "center", textAlign: "center" }}>
                <Col className="col-four" style={{ alignContent: "center" }}>
                <h3>Playlists:</h3>
                    <img src="./img/SwimArtwork.jpg" alt="swim artwork" style={{ height: "400px", margin: "1em auto 1em auto", display: "block" }} />
                    <p>Apple Music: New Music Daily 29/11/24</p>
                </Col>
                <Col className="col-four" style={{ alignContent: "center" }}>
                <h3>Playlists:</h3>
                    <img src="./img/SwimArtwork.jpg" alt="swim artwork" style={{ height: "400px", margin: "1em auto 1em auto", display: "block" }} />
                    <p>BBC Radio 1 Introducing Rock 03/12/24</p>
                </Col>
                <Col className="col-four" style={{ alignContent: "center" }}>
                <h3>Playlists:</h3>
                    <img src="./img/SwimArtwork.jpg" alt="swim artwork" style={{ height: "400px", margin: "1em auto 1em auto", display: "block" }} />
                    <p>BBC Introducing Leeds & Sheffield 28/11/24</p>
                </Col>

            </Row>s
            */}
        </section>
    );
}
